import parse from "html-react-parser";
import IconQuote from "../../assets/icons/quote.png";
// import { ReactComponent as IconQuote } from "../../assets/icons/quote.svg";
import ArrowsExpand from "../../assets/arrows-expand.png";
// import { ReactComponent as ArrowsExpand } from "../../assets/arrows-expand.svg";
import ArrowsCollapse from "../../assets/arrows-collapse.png";
import { useGA4React } from "ga-4-react";
import Scrollbars from "react-custom-scrollbars-2";
// import { ReactComponent as ArrowsCollapse } from "../../assets/arrows-collapse.svg";

export function CardQuote({ cardData }) {
	const strippedHTML = (htmlString) => {
		return htmlString.replace(/<[^>]+>/g, "");
	};

	return (
		<>
			<div className="c-card__quote-wrap">
				<div className="c-card__quote-name">
					<div>
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
					<div data-why="for-hover">
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
				</div>
				<div className="c-card__quote-content">{parse(strippedHTML(cardData.content))}</div>
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconQuote} width={22} height={22} alt="" />
			</div>
			<div className="c-card__quote-controls">
				<button>
					<img src={ArrowsExpand} width={20} height={20} />
				</button>
			</div>
		</>
	);
}

export function CardQuoteActive({ cardData }) {
	const ga4React = useGA4React();

	return (
		<>
			<div className="c-card__quote-wrap">
				<div className="c-card__quote-name">
					<div>
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
					<div data-why="for-hover">
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
				</div>

				<div className="c-card__quote-content">
					{cardData.year && <div className="c-card__quote-year">{cardData.year}</div>}
					<Scrollbars autoHeight autoHeightMin={100} autoHeightMax={500}>
						{parse(cardData.content)}
					</Scrollbars>
				</div>
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconQuote} width={22} height={22} alt="" />
			</div>
			<div className="c-card__quote-controls">
				<button>
					<img src={ArrowsCollapse} width={20} height={20} alt="" />
				</button>
			</div>
		</>
	);
}
