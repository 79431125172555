import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import IconSlideshow from "../../assets/icons/slideshow.png";
// import { ReactComponent as IconSlideshow } from "../../assets/icons/slideshow.svg";
import CardSlides from "../card-slides/CardSlides";
import ImageWithPreloader from "../image-with-preloader/ImageWithPreloader";

export function CardSlideshow({ cardData }) {
	return (
		<>
			<div className="c-card__photo-bg">
				<ImageWithPreloader width={cardData.imgw} height={cardData.imgh} src={cardData.items[0].url} />
			</div>
			<div className="c-card__hover-title">
				<div>{cardData.title}</div>
				<ArrowRight />
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconSlideshow} width={28} height={22} alt="" />
			</div>
		</>
	);
}

export function CardSlideshowActive({ cardData }) {
	return (
		<>
			<div className="content-holder">
				{cardData.year && <div className="c-card__photo-year">{cardData.year}</div>}
				<div className="c-card__header">{cardData.title}</div>
				<CardSlides galleryItems={cardData.items} />
			</div>
		</>
	);
}
