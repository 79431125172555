import React, { useRef } from "react";
import "./CardStory.scss";

import "simplebar-react/dist/simplebar.min.css";
import parse from "html-react-parser";
import CardSlides from "../card-slides/CardSlides";
import VideoPlayer from "../video-player/VideoPlayer";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import IconsStory from "../../assets/icons/story.png";
// import { ReactComponent as IconStory } from "../../assets/icons/story.svg";
import ImageWithPreloader from "../image-with-preloader/ImageWithPreloader";

export function CardStory({ cardData }) {
	return (
		<>
			<div className="c-card__photo-bg">
				{/*<img src={cardData.thumb_url} />*/}
				<ImageWithPreloader src={cardData.thumb_url} />
			</div>
			<div className="c-card__hover-title">
				<div>{cardData.title}</div>
				<ArrowRight />
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconsStory} width={16} height={22} alt="" />
			</div>
		</>
	);
}

export function CardStoryActive({ cardData }) {
	const contentRef = useRef(null);

	return (
		<div className="c-story">
			{/*<SimpleBar>*/}
			<div ref={contentRef} className="content-holder">
				<div className="c-card__photo-year">{cardData.year}</div>
				<div className="c-story__header">
					<span>{cardData.title}</span>
				</div>
				{cardData.intro && <div className="c-story__intro">{cardData.intro}</div>}
				{cardData.blocks.map((block, index) => {
					return (
						<section key={index}>
							{block.type === "text_content" && (
								<div className="c-story__text">
									{block.section_title && (
										<div className="c-story__section-header">
											<span>{block.section_title}</span>
										</div>
									)}
									<div className="c-story__image-desc">
										<div className="c-story__image-desc-name">{block.name}</div>
										<div className="c-story__image-desc-content">{parse(block.content)}</div>
									</div>
								</div>
							)}
							{block.type === "image" && (
								<div className="c-story__image">
									{block.section_title && (
										<div className="c-story__section-header">
											<span>{block.section_title}</span>
										</div>
									)}
									<div className="c-story__image-wrap">
										<ImageWithPreloader width={block.imgw} height={block.imgh} src={block.url} />
									</div>
									{block.name && block.desc && (
										<div className="c-story__image-desc">
											<div className="c-story__image-desc-name">{block.name}</div>
											<div className="c-story__image-desc-content">{block.desc}</div>
										</div>
									)}
								</div>
							)}
							{block.type === "video" && (
								<div className="c-story__video">
									{block.section_title && (
										<div className="c-story__section-header">{block.section_title}</div>
									)}
									<VideoPlayer posterUrl={null} mainVideoUrl={block.url} />
									<div className="c-story__image-desc">
										<div className="c-story__image-desc-name">{block.name}</div>
										<div className="c-story__image-desc-content">{block.desc}</div>
									</div>
								</div>
							)}
							{block.type === "image_gallery" && (
								<div className="c-story__gallery">
									{block.section_title && (
										<div className="c-story__section-header">
											<span>{block.section_title}</span>
										</div>
									)}
									<div className="c-story__gallery-text">{parse(block.content)}</div>
									<div className="c-story__gallery-wrap">
										<CardSlides galleryItems={block.items} />
									</div>
									<div className="c-story__image-desc">
										<div className="c-story__image-desc-name">{block.name}</div>
										<div className="c-story__image-desc-content">{block.desc}</div>
									</div>
								</div>
							)}
						</section>
					);
				})}
			</div>
			{/*</SimpleBar>*/}
		</div>
	);
}
