import React, { useState, useEffect, useReducer, useCallback } from "react";
import LoadingScreen from "./components/loading-screen/LoadingScreen";
import { useFetch } from "use-http";
import CardsSpace from "./components/cards-space/CardsSpace";
import { uiStateReducer, uiInitialState } from "./reducers/uiStateReducer";
import { appStateReducer, appInitialState } from "./reducers/appStateReducer";
import { GA4React } from "ga-4-react";

import styles from "./App.module.scss";
import HeaderLogo from "./assets/logo-20years-2x.png";
import BottomLogo from "./assets/ts-logo-white-2x.png";

export const AppStateContext = React.createContext();
export const UIStateContext = React.createContext();

function App() {
	const fetchBase =
		document.location.host.indexOf("20years") !== -1 ? "https://20years.twosigma.com" : "http://192.168.2.100:3000";
	const fetchEndpoint =
		document.location.host.indexOf("20years.twosigma.com") !== -1
			? "/backend/wp-json/cards/v1/list"
			: "/data/data.json";
	const { get: getData, data, loading, error } = useFetch(fetchBase);

	// const [cardsData, setCardsData] = useState(null);

	const [appState, appStateDispatch] = useReducer(appStateReducer, appInitialState);
	const [uiState, uiStateDispatch] = useReducer(uiStateReducer, uiInitialState);

	const [cardsSpaceIsReady, setCardsSpaceIsReady] = useState(false);

	const [needsRecenter, setNeedsRecenter] = useState(false);

	const ga4react = new GA4React(
		"G-TGH3Y4L86Y",
		{
			/* ga custom config, optional */
		},
		[
			/* additional code, optional */
		],
		5000 /* timeout, optional, defaults is 5000 */
	);

	useEffect(() => {
		console.log("GA4: Trying to init GA4 code");
		ga4react.initialize().then(
			(ga4) => {
				console.log("GA4: track initial page:", ga4);
				ga4.pageview("");
				appStateDispatch({ type: "setGA4Tracker", value: ga4 });
			},
			(err) => {
				console.log("GA4: error:", err);
				console.error(err);
			}
		);
	}, []);

	useEffect(() => {
		getData(fetchEndpoint);
	}, [getData, fetchEndpoint]);

	useEffect(() => {
		if (!data) return;
		appStateDispatch({ type: "setCardsData", value: data.cards });
		appStateDispatch({ type: "initCardsOrder" });
	}, [data]);

	useEffect(() => {
		console.log("App state changed:", appState);
	}, [appState]);

	const [zeroTransition, setZeroTransition] = useState(false);
	const handleCardsSpaceOnReady = useCallback(() => {
		//we get this callback from CardsSpace when it is ready to be shown
		console.log("Handle card space on ready ****");
		setCardsSpaceIsReady(true);
		setTimeout(() => {
			setZeroTransition(true);
			uiStateDispatch({ type: "setShowOnboarding", value: true });
		}, 3000);
	}, []);

	const getCardSpaceClasses = () => {
		if (loading || appState.cardsData.length === 0) {
			return "card-space-default";
		}
		var result = cardsSpaceIsReady ? "card-space-ready" : "card-space-notready";
		if (zeroTransition && cardsSpaceIsReady) {
			result = result + " card-space-done";
		}
		return result;
	};

	return (
		<UIStateContext.Provider value={{ uiState: uiState, uiStateDispatch: uiStateDispatch }}>
			<AppStateContext.Provider value={{ appState: appState, appStateDispatch: appStateDispatch }}>
				{/*<div>--{appState.cardsData.length}--</div>*/}
				{error && <div>Error loading data!</div>}
				{(loading || cardsSpaceIsReady === false) && <LoadingScreen />}
				{appState.cardsData.length > 0 && (
					<CardsSpace
						extraClassName={getCardSpaceClasses()}
						onReady={handleCardsSpaceOnReady}
						needsRecenter={needsRecenter}
						setNeedsRecenter={() => {
							setNeedsRecenter();
						}}
					/>
				)}
				<div
					className={`${styles.logo} ${
						uiState.activeCardIndex === null
							? ""
							: appState.cardsData[appState.cardsGridArray[uiState.activeCardIndex].cardDataIdx].type ===
							  "quote"
							? ""
							: styles.logohidden
					}`}
				>
					<img src={HeaderLogo} alt="Two Sigma 20 Years Anniversary" />
				</div>
				<div
					className={`${styles.bottomlogo} ${
						uiState.activeCardIndex === null
							? ""
							: appState.cardsData[appState.cardsGridArray[uiState.activeCardIndex].cardDataIdx].type ===
							  "quote"
							? ""
							: styles.bottomlogohidden
					}`}
				>
					<img src={BottomLogo} alt="Two Sigma" />
				</div>
				{uiState.activeCardIndex === null && (
					<div className={styles.buttonsHolder}>
						{/*<button*/}
						{/*	className={styles.butContribute}*/}
						{/*	onClick={(e) => {*/}
						{/*		e.preventDefault();*/}
						{/*		window.open("/contribute/");*/}
						{/*	}}*/}
						{/*>*/}
						{/*	Contribute*/}
						{/*</button>*/}
						<button
							className={styles.butCenter}
							onClick={(e) => {
								e.preventDefault();
								setNeedsRecenter(true);
							}}
						>
							Center
						</button>
					</div>
				)}
				{uiState.showOnboarding && (
					<div
						className={`${styles.onboarding} ${
							uiState.onboardingDismissed ? styles.onboardingDismissed : null
						}`}
					>
						Drag anywhere on the screen to explore
					</div>
				)}
			</AppStateContext.Provider>
		</UIStateContext.Provider>
	);
}

export default App;
