import React, { useState, useRef } from "react";
import blankPNG from "../../assets/blank.png";
import LoaderLogo from "../../assets/20-logo.png";
// import { ReactComponent as LoaderLogo } from "../../assets/20-logo.svg";
import { CSSTransition } from "react-transition-group";

function ImageWithPreloader({ src, width, height, classNames, style }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const nodeRef = useRef(null);
	return (
		<>
			<img
				onLoad={() => setIsLoaded(true)}
				src={src}
				width={width}
				height={height}
				className={classNames}
				style={style}
				alt=""
			/>

			{/*<CSSTransition in={!isLoaded} classNames="preloader" unmountOnExit timeout={2000}>*/}
			{/*	<div ref={nodeRef} className="preloader">*/}
			{/*		<img src={blankPNG} width={width} height={height} alt="" />*/}
			{/*		/!*<img className="loader-logo" src={LoaderLogo} alt="" />*!/*/}
			{/*	</div>*/}
			{/*</CSSTransition>*/}
		</>
	);
}

export default ImageWithPreloader;
