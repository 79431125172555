import React, { useRef, useEffect, useState, useCallback, useMemo, useContext } from "react";

import parse from "html-react-parser";

import CardsRelated from "../cards-related/CardsRelated";

import { CardStory, CardStoryActive } from "../card-story-active/CardStoryActive";
import { CardQuote, CardQuoteActive } from "../cards-quote/CardsQuote";
import { CardPhoto, CardPhotoActive } from "../cards-photo/CardsPhoto";
import { CardVideo, CardVideoActive } from "../cards-video/CardsVideo";
import { CardSlideshow, CardSlideshowActive } from "../cards-slideshow/CardsSlideshow";
import { AppStateContext, UIStateContext } from "../../App";

function Card({
	cardGridArrayIndex,
	handleCardClick,
	targetStyleLeft,
	targetStyleTop,
	targetStyleTransitionDuration,
	isMoreMemoriesCard,
	screenWidth,
	screenHeight,
}) {
	const cardRef = useRef(null);
	const { appState } = useContext(AppStateContext);
	const { uiState, uiStateDispatch } = useContext(UIStateContext);
	const quoteContentTesterRef = useRef(null);
	const quoteContentBoxRef = useRef(null);
	const quoteContentHeadRef = useRef(null);
	const [cardZIndex, setCardZIndex] = useState(0);
	const cardZValue = appState.cardsGridArray[cardGridArrayIndex].zValue;

	const [cardActiveWidth, setCardActiveWidth] = useState(null);

	const [isHovered, setIsHovered] = useState(false);

	const [isTransforming, setIsTransforming] = useState(true);

	const isActive = uiState.activeCardIndex === cardGridArrayIndex;
	const isUpcoming = uiState.upcomingCardIndex === cardGridArrayIndex;

	const cardData = appState.cardsData[appState.cardsGridArray[cardGridArrayIndex].cardDataIdx];

	const targetStyle = {
		left: targetStyleLeft,
		top: targetStyleTop,
		transitionDuration: targetStyleTransitionDuration,
	};

	const quoteHeadHeight = useMemo(() => {
		if (!quoteContentTesterRef.current || !quoteContentHeadRef.current) return;
		if (
			cardData.type === "quote" &&
			(cardGridArrayIndex === uiState.upcomingCardIndex || cardGridArrayIndex === uiState.activeCardIndex)
		) {
			console.log(
				"Trying to calculate quote head height:",
				cardGridArrayIndex,
				uiState.upcomingCardIndex,
				quoteContentHeadRef.current.offsetHeight
			);
			const s = window.getComputedStyle(quoteContentHeadRef.current, null);
			return quoteContentHeadRef.current.offsetHeight + parseInt(s.getPropertyValue("margin-bottom"));
		} else {
			return 0;
		}
	}, [
		quoteContentTesterRef,
		quoteContentHeadRef,
		cardData,
		uiState.upcomingCardIndex,
		cardGridArrayIndex,
		uiState.activeCardIndex,
	]);

	const quoteContentHeight = useMemo(() => {
		if (!quoteContentTesterRef.current || !quoteContentBoxRef.current) return;
		if (
			cardData.type === "quote" &&
			(cardGridArrayIndex === uiState.upcomingCardIndex || cardGridArrayIndex === uiState.activeCardIndex)
		) {
			console.log("Trying to calculate quote content height:", quoteContentBoxRef.current.offsetHeight);
			return quoteContentBoxRef.current.offsetHeight;
		} else {
			return 0;
		}
	}, [
		quoteContentTesterRef,
		quoteContentBoxRef,
		cardData,
		uiState.upcomingCardIndex,
		cardGridArrayIndex,
		uiState.activeCardIndex,
	]);

	const getCardLayout = useCallback(() => {
		switch (cardData.type) {
			case "photo":
				return <CardPhoto cardData={cardData} />;
			case "quote":
				return <CardQuote cardData={cardData} />;
			case "slideshow":
				return <CardSlideshow cardData={cardData} />;
			case "story":
				return <CardStory cardData={cardData} />;
			case "video":
				return <CardVideo cardData={cardData} isHovered={isHovered} />;
			default:
				return <div>Unhandled type: {cardData.type}</div>;
		}
	}, [cardData, isHovered]);

	const getActiveCardLayout = () => {
		switch (cardData.type) {
			case "photo":
				return <CardPhotoActive cardData={cardData} />;
			case "quote":
				return <CardQuoteActive cardData={cardData} />;
			case "slideshow":
				return <CardSlideshowActive cardData={cardData} />;
			case "story":
				return <CardStoryActive cardData={cardData} />;
			case "video":
				return <CardVideoActive cardData={cardData} />;
			default:
				return <div>Unhandled type: {cardData.type}</div>;
		}
	};

	const hoveredStyle = () => {
		return isHovered && !isActive ? "is-hovered" : "";
	};
	const activeStyle = () => {
		return isActive ? "is-active" : "";
	};

	const getCardNormalWidth = useCallback(() => {
		// console.log("Get card normal width:", cardData.type);
		return uiState.defaultCardWidth;
	}, [uiState.defaultCardWidth]);

	useEffect(() => {
		// console.log("Quote card active width recalc:", cardData.type);
		switch (cardData.type) {
			case "quote":
				setCardActiveWidth(Math.min(screenWidth, 500));
				break;
			default:
				setCardActiveWidth("100vw");
		}
	}, [cardGridArrayIndex, screenWidth, cardData]);

	const getCardNormalHeight = useCallback(() => {
		return uiState.defaultCardHeight;
	}, [uiState.defaultCardHeight]);

	const activeHeight = useMemo(() => {
		switch (cardData.type) {
			case "quote":
				// console.log("Getting quote height:", quoteContentHeight);
				return Math.min(500, Math.max(250, quoteContentHeight + quoteHeadHeight + 40));
			default:
				return "100vh"; //window.innerHeight;
		}
	}, [quoteContentHeight, cardData.type, quoteHeadHeight]);

	const getCardTransformValues = useCallback(() => {
		if (isMoreMemoriesCard) {
			return `translate(0,0)`;
		}

		if (isActive) {
			if (cardData.type === "quote") {
				const targetLeft = Math.round(targetStyle.left - cardActiveWidth / 2);
				const targetTop = Math.round(targetStyle.top - activeHeight / 2);
				if (cardActiveWidth < 500) {
					return `translate(0px,${targetTop}px) translateZ(0)`;
				} else {
					return `translate(${targetLeft}px,${targetTop}px) translateZ(0)`;
				}
			} else {
				console.log("Card is active:", cardData.type);
				return `translate(0px,0px)`;
			}
		} else if (isHovered) {
			const targetLeft = Math.round(targetStyle.left - getCardNormalWidth() / 2); // + zIndexShiftLeft);
			const targetTop = Math.round(targetStyle.top - getCardNormalHeight() / 2); // + zIndexShiftTop);
			return `translate(${targetLeft}px,${targetTop}px)`;
		} else {
			//card is just a normal thing
			const targetLeft = Math.round(targetStyle.left - getCardNormalWidth() / 2); // + zIndexShiftLeft);
			const targetTop = Math.round(targetStyle.top - getCardNormalHeight() / 2); // + zIndexShiftTop);
			return `translate(${targetLeft}px,${targetTop}px) translateZ(0)`;
		}
	}, [
		isActive,
		activeHeight,
		cardActiveWidth,
		cardData.type,
		getCardNormalHeight,
		getCardNormalWidth,
		isHovered,
		isMoreMemoriesCard,
		targetStyle.left,
		targetStyle.top,
	]);

	useEffect(() => {
		if (isHovered && !isMoreMemoriesCard) {
			setCardZIndex(110);
		} else {
			setCardZIndex(Math.round(cardZValue * 100).toFixed(0));
		}
	}, [isHovered, cardZValue, isMoreMemoriesCard]);

	useEffect(() => {
		if (isActive || isHovered) {
			if (isHovered) {
				setCardZIndex(110);
			} else {
				setCardZIndex(111);
			}
		} else {
			//when inactive (actually, becoming inactive, we delay zindex switch
			if (cardZIndex !== 0) {
				setCardZIndex(Math.round(cardZValue * 100).toFixed(0));
			}
		}
	}, [isActive, isHovered, cardZValue, cardZIndex]);

	useEffect(() => {
		if (isActive) {
			setTimeout(() => {
				setIsTransforming(false);
			}, 1000);
		} else {
			setIsTransforming(true);
		}
	}, [isActive]);

	useEffect(() => {
		if (isUpcoming) {
			setIsTransforming(true);
		}
	}, [isUpcoming]);

	const getPreloadLayout = ({ shouldFadeOut }) => {
		var preloadImageURL = "";
		switch (cardData.type) {
			case "story":
				preloadImageURL = cardData.thumb_url;
				break;
			case "photo":
				preloadImageURL = cardData.thumb_url;
				break;
			case "slideshow":
				preloadImageURL = cardData.items[0].url;
				break;
			case "video":
				preloadImageURL = cardData.thumb_url;
				break;
		}
		return (
			<div className={`c-card__preloader ${shouldFadeOut ? "fadeout" : ""}`}>
				<img src={preloadImageURL} />
			</div>
		);
	};

	const getCurrentLayout = () => {
		if (cardData.type === "quote") {
			if (isUpcoming || isActive) {
				return getActiveCardLayout();
			} else {
				return getCardLayout();
			}
		} else {
			//all other cards
			if (isActive || isUpcoming) {
				// return (
				// 	<>
				// 		{getActiveCardLayout()}
				// 		{getPreloadLayout({ shouldFadeOut: !isTransforming })}
				// 	</>
				// );
				if (isTransforming) {
					return (
						//card is being expanded
						<>
							{/*{getActiveCardLayout()}*/}
							{getPreloadLayout({ shouldFadeOut: false })}
						</>
					);
				} else {
					return (
						<>
							{getActiveCardLayout()}
							{getPreloadLayout({ shouldFadeOut: true })}
						</>
					);
				}
			} else {
				return getCardLayout();
			}
		}
	};

	return (
		<div
			ref={cardRef}
			style={{
				transform: getCardTransformValues(),
				width: isActive ? cardActiveWidth : getCardNormalWidth(),
				height: isActive ? activeHeight : getCardNormalHeight(),
				transitionDuration: targetStyle.transitionDuration,
				zIndex: cardZIndex,
				overflow: isTransforming ? "hidden" : "hidden auto",
				position: isMoreMemoriesCard ? "relative" : "",
			}}
			className={`c-card c-card--${cardData.type} ${hoveredStyle()} ${activeStyle()}`}
			onClick={(e) => {
				console.log("uiState - click:", isActive, cardData.type, cardGridArrayIndex, uiState.maxLastDrag);
				if (isActive === false && uiState.maxLastDrag < 30) {
					uiStateDispatch({ type: "setUpcomingIndex", value: cardGridArrayIndex });
					return;
				}

				if (isActive === true && cardData.type === "quote") {
					e.preventDefault();
					e.stopPropagation();
					uiStateDispatch({ type: "clearActiveCardIndex", value: cardGridArrayIndex });
					return;
				} else if (isActive === true && isMoreMemoriesCard) {
					uiStateDispatch({ type: "setUpcomingIndex", value: cardGridArrayIndex });
				}
				e.preventDefault();
				e.stopPropagation();

				//finally, we still setting last drag to zero:
				uiStateDispatch({ type: "setMaxLastDrag", value: 0 });
			}}
			onMouseEnter={() => {
				if (uiState.isMoving) return;
				setIsHovered(true);
				uiStateDispatch({ type: "setHoveredCardIndex", value: cardGridArrayIndex });
			}}
			onMouseLeave={() => {
				setIsHovered(false);
				uiStateDispatch({ type: "clearHoveredCardIndex" });
			}}
		>
			{getCurrentLayout()}
			{isActive && !isTransforming && cardData.type !== "quote" && (
				<CardsRelated currentCardGridArrayIndex={cardGridArrayIndex} handleCardClick={handleCardClick} />
			)}
			{/*<div*/}
			{/*	className="c-card__index"*/}
			{/*	style={{*/}
			{/*		background: "black",*/}
			{/*		color: "white",*/}
			{/*		padding: "2px 5px",*/}
			{/*		border: "1px solid white",*/}
			{/*		// display: "none",*/}
			{/*	}}*/}
			{/*>*/}
			{/*	{cardGridArrayIndex}=>{isActive ? "ACT" : "---"}*/}
			{/*	{isUpcoming ? "UPC" : "---"}*/}
			{/*	{isTransforming ? "TRF" : "---"}*/}
			{/*</div>*/}

			{/* placeholder for quote card height calculations */}
			{cardData.type === "quote" && (
				<div
					ref={quoteContentTesterRef}
					style={{
						position: "absolute",
						width: cardActiveWidth,
						visibility: "hidden",
					}}
				>
					<div className="c-card__quote-name" ref={quoteContentHeadRef}>
						{cardData.firsname}
						<br />
						{cardData.lastname}
					</div>
					<div className="c-card__quote-content c-card__quote-content-unlimited" ref={quoteContentBoxRef}>
						{cardData.year && <div className="c-card__quote-year">{cardData.year}</div>}
						{parse(cardData.content)}
					</div>
				</div>
			)}
		</div>
	);
}

export default Card;
