export const uiInitialState = {
	activeCardIndex: null,
	upcomingCardIndex: null,
	isMoving: false,
	hoveredCardIndex: null,
	centeredCardIndex: null,
	defaultCardWidth: 250,
	defaultCardHeight: 250,
	maxLastDrag: 0,
	showOnboarding: false,
	onboardingDismissed: false,
};

export const uiStateReducer = (state, action) => {
	// console.log("TRY uiState Reducer:", action);
	switch (action.type) {
		case "triggerActiveCardIndex":
			if (state.activeCardIndex === null) {
				return { ...state, activeCardIndex: action.value, centeredCardIndex: action.value };
			} else {
				return { ...state, activeCardIndex: null, centeredCardIndex: null };
			}
		case "setActiveCardIndex": //indicies are of the cards from cardsGridArray : the actual cards on screen
			// console.log("uiState Reducer:", action);
			return { ...state, activeCardIndex: action.value, centeredCardIndex: action.value };
		case "clearActiveCardIndex":
			// console.log("uiState Reducer:", action);
			return { ...state, activeCardIndex: null, centeredCardIndex: null };
		case "setHoveredCardIndex":
			// if (state.isMoving) return state;
			// console.log("uiState Reducer:", action);
			return { ...state, hoveredCardIndex: action.value };
		case "clearHoveredCardIndex":
			if (state.isMoving) return state;
			// console.log("uiState Reducer:", action);
			return { ...state, hoveredCardIndex: null };
		case "setUpcomingIndex":
			// console.log("uiState Reducer:", action);
			return { ...state, upcomingCardIndex: action.value, maxLastDrag: 0 };
		case "clearUpcomingIndex":
			// console.log("uiState Reducer:", action);
			return { ...state, upcomingCardIndex: null };
		case "beginMoving":
			// console.log("uiState Reducer:", action);
			return { ...state, isMoving: true };
		case "endMoving":
			// console.log("uiState Reducer:", action);
			return { ...state, isMoving: false };
		case "setDefaultCardSize":
			//both width and height are the same
			console.log("Settings cards width and height:", action.value);
			return { ...state, defaultCardWidth: action.value, defaultCardHeight: action.value };
		case "setMaxLastDrag":
			return { ...state, maxLastDrag: action.value };
		case "setShowOnboarding":
			return { ...state, showOnboarding: action.value };
		case "setOnboardingDismissed":
			return { ...state, onboardingDismissed: action.value };
		default:
			// throw new Error("unknown ui dispatch:", action.type, action.value);
			return state;
	}
};
