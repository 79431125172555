import React from "react";
import Slider from "react-slick";
import "./CardSlides.scss";
import { ReactComponent as SlideshowCurl } from "../../assets/slideshow-curl.svg";
import ImageWithPreloader from "../image-with-preloader/ImageWithPreloader";

function CardSlides({ galleryItems }) {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	};

	return (
		<div className="c-slideshow">
			<Slider {...settings}>
				{galleryItems.map((item, index) => {
					return (
						<div className="c-card__slideshow-item" key={index}>
							<div className="c-slideshow__image-wrap">
								<img src={item.url} className="c-slideshow__image-bg" alt="" />
								<ImageWithPreloader src={item.url} />
								{/*<img src={item.url} />*/}
								<div className="c-slideshow__curl">
									<SlideshowCurl />
									<div className="c-slideshow__num">
										{index + 1}
										<span>/</span>
										{galleryItems.length}
									</div>
								</div>
							</div>
							<div className="c-card__photo-content">
								{(item.title || item.name) && (
									<div className="c-card__photo-content-name">{item.title || item.name}</div>
								)}
								<div className="c-card__photo-content-desc">{item.desc}</div>
							</div>
						</div>
					);
				})}
			</Slider>
		</div>
	);
}

export default CardSlides;
