import React, { useEffect, useState, useRef, useMemo, useContext, useCallback } from "react";
import "./CardsSpace.scss";
import Card from "../Card/Card";
import { AppStateContext, UIStateContext } from "../../App";
import { useWindowSize } from "../../hooks/useWindowSize";

function CardsSpace({ extraClassName, onReady, needsRecenter, setNeedsRecenter }) {
	const { appState, appStateDispatch } = useContext(AppStateContext);
	const { uiState, uiStateDispatch } = useContext(UIStateContext);
	const { activeCardIndex, upcomingCardIndex, isMoving } = uiState;

	const cardSwitchCenterTimeout = 500;
	const cardSwitchLightboxTransformTimeout = 500;

	const [initialStartInProgress, setInitialStartInProgress] = useState(true);

	const [gridWidthRatio] = useState(1.8);
	const [gridHeightRatio] = useState(1.75);

	const [newCardRunningIndex, setNewCardRunningIndex] = useState(0);

	const cardsWrapperRef = useRef(null);

	const [isMouseOver, setIsMouseOver] = useState(false);

	const { width: screenWidth, height: screenHeight } = useWindowSize();

	const spaceGridWidth = (uiState.defaultCardWidth * gridWidthRatio) / 2; //pixels
	const spaceGridHeight = (uiState.defaultCardHeight * gridHeightRatio) / 2;
	//cards positions randomization around grid point
	const cardShiftRatioX = 0.4; // +/- this value * cardWidth, random position
	const cardShiftRatioY = 0.3;
	const backgroundShiftMultiplier = 0.2;
	const [spaceShiftX, setSpaceShiftX] = useState(0);
	const [spaceShiftY, setSpaceShiftY] = useState(0);

	const [spaceShiftXRecalcPoint, setSpaceShiftXRecalcPoint] = useState(-9999);
	const [spaceShiftYRecalcPoint, setSpaceShiftYRecalcPoint] = useState(-9999);

	const handleUserKeyPress = (event) => {
		const { keyCode } = event;
		if (keyCode === 27) {
			//escape keycode
			if (uiState.activeCardIndex !== null) {
				uiStateDispatch({ type: "clearActiveCardIndex" });
			}
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleUserKeyPress);
		return () => {
			window.removeEventListener("keydown", handleUserKeyPress);
		};
	}, [uiState]);

	useEffect(() => {
		console.log("Window dimensions:", screenWidth, screenHeight);
		if (screenWidth === 0 || screenHeight === 0) return;
		if (screenWidth < 670 || screenHeight < 670) {
			//mobile small
			uiStateDispatch({ type: "setDefaultCardSize", value: 150 });
			return () => {};
		}
		if (screenWidth < 1100 && screenHeight < 1100) {
			uiStateDispatch({ type: "setDefaultCardSize", value: 200 });
			return () => {};
		}
		uiStateDispatch({ type: "setDefaultCardSize", value: 250 });
	}, [screenWidth, screenHeight, uiStateDispatch]);

	const visibleGridItems = useMemo(() => {
		// console.log("visible items, should recenter?", initialStartInProgress);
		if (
			!initialStartInProgress &&
			Math.abs(spaceShiftX - spaceShiftXRecalcPoint) < spaceGridWidth &&
			Math.abs(spaceShiftY - spaceShiftYRecalcPoint) < spaceGridHeight
		) {
			// console.log("visible grid items bailing out");
			return [];
		}

		setSpaceShiftXRecalcPoint(spaceShiftX);
		setSpaceShiftYRecalcPoint(spaceShiftY);

		var screenCenterGridX = Math.round((screenWidth / 2 - spaceShiftX) / spaceGridWidth);
		var screenCenterGridY = Math.round((screenHeight / 2 - spaceShiftY) / spaceGridHeight);

		var visibleHalfGridPointsX = Math.ceil(screenWidth / spaceGridWidth);
		var visibleHalfGridPointsY = Math.ceil(screenHeight / spaceGridHeight);

		var startingVisibleGridX = screenCenterGridX - visibleHalfGridPointsX;
		var endingVisibleGridX = screenCenterGridX + visibleHalfGridPointsX;
		var startingVisibleGridY = screenCenterGridY - visibleHalfGridPointsY;
		var endingVisibleGridY = screenCenterGridY + visibleHalfGridPointsY;

		// console.log(`${screenCenterGridX}-${screenCenterGridY} ===  ${startingVisibleGridX} x ${endingVisibleGridX}`);

		var visibleGridPoints = [];
		for (var i = startingVisibleGridX; i <= endingVisibleGridX; i++) {
			for (var j = startingVisibleGridY; j <= endingVisibleGridY; j++) {
				visibleGridPoints.push({ x: i, y: j });
			}
		}

		console.log(
			"Visible grid points:",
			"total:",
			visibleGridPoints.length
			// visibleGridPoints,
			// spaceShiftX,
			// spaceShiftY,
			// screenWidth,
			// screenHeight
		);
		return visibleGridPoints;
	}, [
		spaceShiftX,
		spaceShiftY,
		screenWidth,
		screenHeight,
		spaceGridWidth,
		spaceGridHeight,
		initialStartInProgress,
		// spaceShiftXRecalcPoint,
		// spaceShiftYRecalcPoint,
	]);

	const centerOnPinnedCards = () => {
		if (!appState.cardsGridArray || appState.cardsGridArray.length < 10) return;

		var pinnedAggregatedX = 0;
		var pinnedAggregatedY = 0;
		var pinnedCount = 0;

		console.log("Will try to center on pinned cards:", appState.cardsGridArray);

		for (const cardIdx in appState.cardsGridArray) {
			const card = appState.cardsGridArray[cardIdx];
			console.log("Processing card:", card);
			if (!card.isPinned) break;
			pinnedAggregatedX += card.gridX;
			pinnedAggregatedY += card.gridY;
			pinnedCount++;
		}

		const centerGridX = pinnedAggregatedX / pinnedCount;
		const centerGridY = pinnedAggregatedY / pinnedCount;

		console.log("Pinned cards grid center:", centerGridX, centerGridY);

		const targetLeft = centerGridX * spaceGridWidth; // - screenWidth / 2;
		const targetTop = centerGridY * spaceGridHeight; // - screenHeight / 2;

		setSpaceShiftX(screenWidth / 2 - targetLeft);
		setSpaceShiftY(screenHeight / 2 - targetTop);
	};

	useEffect(() => {
		// console.log("Recenter was requested");
		if (needsRecenter) {
			centerOnPinnedCards();
			setNeedsRecenter(false);
		}
	}, [needsRecenter, setNeedsRecenter]);

	const centerOnCardIdx = (idx) => {
		if (appState.cardsGridArray.length < idx) return;

		const { gridX, gridY } = appState.cardsGridArray[idx];
		console.log("Centering on:", gridX, gridY);

		const shiftRatioX = appState.cardsGridArray[idx].shiftRatioX;
		const shiftRatioY = appState.cardsGridArray[idx].shiftRatioY;

		const targetLeft = gridX * spaceGridWidth + shiftRatioX * uiState.defaultCardWidth;
		const targetTop = gridY * spaceGridHeight + shiftRatioY * uiState.defaultCardHeight;

		setSpaceShiftX(screenWidth / 2 - targetLeft);
		setSpaceShiftY(screenHeight / 2 - targetTop);
	};

	const isGridInArray = (gridX, gridY) => {
		const found = appState.cardsGridArray.find((gridCardsArrayItem) => {
			return gridCardsArrayItem.gridX === gridX && gridCardsArrayItem.gridY === gridY;
		});
		return found !== undefined;
	};

	//we're getting automatically calculated set of visible grid items
	const populateGridWithPoints = (gridPointsArray) => {
		var gridPointsToAdd = [];
		var gridItemsToAdd = [];

		var runningIndex = newCardRunningIndex;

		gridPointsArray.forEach(({ x: gridX, y: gridY }) => {
			// console.log("GridPointsArray:", gridX, gridY, (gridX + gridY) % 2);
			if ((gridX + gridY) % 2 === 0) {
				// console.log("GridPointsArray: PASSED");
				//only adding grid items that can be populated
				if (!isGridInArray(gridX, gridY)) {
					gridPointsToAdd.push({
						gridX: gridX,
						gridY: gridY,
						layerNumber: gridX % 2 === 0 ? 0 : 1,
					});
				}
			}
		});

		if (gridPointsToAdd.length > 0) {
			// console.log("Adding to grid items count:", gridItemsToAdd);
			gridPointsToAdd.sort((a, b) => {
				if (Math.abs(a.gridX) + Math.abs(a.gridY) < Math.abs(b.gridX) + Math.abs(b.gridY)) {
					return -1;
				}
				if (Math.abs(a.gridX) + Math.abs(a.gridY) > Math.abs(b.gridX) + Math.abs(b.gridY)) {
					return 1;
				}
				return 0;
			});
			// console.log("Adding grid sorted items:", gridItemsToAdd);

			gridPointsToAdd.forEach(({ gridX, gridY, layerNumber }) => {
				const cardIsPinned = appState.cardsData[runningIndex].is_pinned;
				var targetZValue = 0;
				if (cardIsPinned) {
					targetZValue = 1.01;
				} else {
					targetZValue = layerNumber === 0 ? Math.random() * 0.1 : 0.7 + Math.random() * 0.3;
				}
				gridItemsToAdd.push({
					gridX: gridX,
					gridY: gridY,
					zValue: targetZValue,
					cardDataIdx: runningIndex,
					isPinned: appState.cardsData[runningIndex].is_pinned,
					shiftRatioX: cardIsPinned ? 0 : cardShiftRatioX - Math.random() * cardShiftRatioX * 2,
					shiftRatioY: cardIsPinned ? 0 : cardShiftRatioY - Math.random() * cardShiftRatioY * 2,
				});

				runningIndex = runningIndex + 1 > appState.cardsData.length - 1 ? 0 : runningIndex + 1;
			});

			appStateDispatch({ type: "addItemsToCardsGridArray", value: gridItemsToAdd });
			// setGridCardsArray(gridCardsArray.concat(gridItemsToAdd));
		}

		setNewCardRunningIndex(runningIndex);
	};

	// this is the main cards switch effect
	useEffect(() => {
		if (uiState.isMoving === true) {
			console.log("uiState Still moving, bailing");
			return () => {};
		}

		if (uiState.upcomingCardIndex !== null) {
			console.log("uiState Upcoming non-null:", uiState.upcomingCardIndex);
			uiStateDispatch({ type: "beginMoving" });
			uiStateDispatch({ type: "clearHoveredCardIndex" });
			if (uiState.activeCardIndex === null) {
				//just opening after centering
				centerOnCardIdx(uiState.upcomingCardIndex);
				setTimeout(() => {
					if (appState.ga4tracker) {
						appState.ga4tracker.gtag("event", "page_view", {
							page_location: document.location.origin + "/card/" + uiState.upcomingCardIndex,
						});
					} else {
						// debugger;
						console.error("GA4 tracker can't be used");
					}
					uiStateDispatch({ type: "setActiveCardIndex", value: uiState.upcomingCardIndex });
					setTimeout(() => {
						uiStateDispatch({ type: "clearUpcomingIndex" });
						uiStateDispatch({ type: "endMoving" });
					}, cardSwitchLightboxTransformTimeout);
				}, cardSwitchCenterTimeout);
			} else {
				//we already have an active card, so we need to remove it first
				// and present a new "upcoming" one next
				uiStateDispatch({ type: "clearActiveCardIndex" });
				setTimeout(() => {
					centerOnCardIdx(uiState.upcomingCardIndex);
					setTimeout(() => {
						uiStateDispatch({ type: "endMoving" });
					}, cardSwitchCenterTimeout);
				}, cardSwitchLightboxTransformTimeout);
			}
		}
	}, [
		isMoving,
		activeCardIndex,
		upcomingCardIndex,
		// centerOnCardIdx,
		uiState.activeCardIndex,
		uiState.isMoving,
		uiState.upcomingCardIndex,
		uiStateDispatch,
	]);

	useEffect(() => {
		populateGridWithPoints(visibleGridItems);
	}, [visibleGridItems]);

	const handleCloseCard = () => {
		uiStateDispatch({ type: "clearActiveCardIndex" });
	};

	const [isDragInProgress, setIsDragInProgress] = useState(false);
	const [{ dragStartX, dragStartY }, setDragStart] = useState({ dragStartX: 0, dragStartY: 0 });
	const [{ spaceShiftOnDragStartX, spaceShiftOnDragStartY }, setShiftOnDragStart] = useState({
		spaceShiftOnDragStartX: 0,
		spaceShiftOnDragStartY: 0,
	});

	const handleGeneralPointerDown = (e) => {
		if (uiState.showOnboarding && !uiState.onboardingDismissed) {
			console.log("Dismissing onboarding");
			uiStateDispatch({ type: "setOnboardingDismissed", value: true });
		}

		console.log("Pointer down type:", e.type);
		setIsDragInProgress(true);
		if (e.type === "touchstart") {
			setDragStart({
				dragStartX: e.touches[0].clientX,
				dragStartY: e.touches[0].clientY,
			});
		} else {
			setDragStart({
				dragStartX: e.clientX,
				dragStartY: e.clientY,
			});
		}

		setShiftOnDragStart({
			spaceShiftOnDragStartX: spaceShiftX,
			spaceShiftOnDragStartY: spaceShiftY,
		});
	};
	const handleGeneralPointerUp = (e) => {
		if (
			Math.abs(spaceShiftX - spaceShiftOnDragStartX) > 30 ||
			Math.abs(spaceShiftY - spaceShiftOnDragStartY) > 30
		) {
			//preventing the actual click event
			e.preventDefault();
			e.stopPropagation();
			console.log("Preventing propagation");
		} else {
			console.log("Card can actually be clicked");
		}
		setTimeout(() => {
			uiStateDispatch({ type: "setMaxLastDrag", value: 0 });
		}, 100);
	};

	const handleGeneralPointerMove = (e) => {
		if (uiState.activeCardIndex !== null) return;

		if (isDragInProgress) {
			// console.log("Mouse is moving on cards holder ref:");
			if (e.type === "touchmove") {
				setSpaceShiftX(spaceShiftOnDragStartX + e.touches[0].clientX - dragStartX);
				setSpaceShiftY(spaceShiftOnDragStartY + e.touches[0].clientY - dragStartY);
				if (e.touches[0].clientX - dragStartX > uiState.maxLastDrag) {
					uiStateDispatch({ type: "setMaxLastDrag", value: e.touches[0].clientX - dragStartX });
				} else if (e.touches[0].clientY - dragStartY > uiState.maxLastDrag) {
					uiStateDispatch({ type: "setMaxLastDrag", value: e.touches[0].clientY - dragStartY });
				}
			} else {
				setSpaceShiftX(spaceShiftOnDragStartX + e.clientX - dragStartX);
				setSpaceShiftY(spaceShiftOnDragStartY + e.clientY - dragStartY);
				if (e.clientX - dragStartX > uiState.maxLastDrag) {
					uiStateDispatch({ type: "setMaxLastDrag", value: e.clientX - dragStartX });
				} else if (e.clientY - dragStartY > uiState.maxLastDrag) {
					uiStateDispatch({ type: "setMaxLastDrag", value: e.clientY - dragStartY });
				}
			}
		}
	};

	const handleMouseDown = (e) => {
		if (uiState.activeCardIndex !== null) return;
		if (e.altKey || e.ctrlKey || e.button === 2) {
			e.preventDefault();
			e.stopPropagation();
			return false;
		} // we don't accept modifier keys

		document.body.style.cursor = "grabbing";

		console.log("Mouse is down on cards holder ref:", spaceShiftX, spaceShiftY);
		handleGeneralPointerDown(e);
	};

	const handleTouchStart = (e) => {
		if (uiState.activeCardIndex !== null) return;
		handleGeneralPointerDown(e);
	};

	const handleMouseUp = (e) => {
		if (uiState.activeCardIndex !== null) return;
		setIsDragInProgress(false);
		document.body.style.cursor = "grab";
		handleGeneralPointerUp(e);
	};
	const handleTouchEnd = (e) => {
		if (uiState.activeCardIndex !== null) return;
		setIsDragInProgress(false);
		handleGeneralPointerUp(e);
	};

	const handleMouseMove = (e) => {
		handleGeneralPointerMove(e);
	};
	const handleTouchMove = (e) => {
		handleGeneralPointerMove(e);
	};

	useEffect(() => {
		if (!isMouseOver) {
			console.log("Not dragging anymore");
			setIsDragInProgress(false);
		}
		document.body.style.cursor = "grab";
	}, [isMouseOver]);

	useEffect(() => {
		if (initialStartInProgress === false) {
			console.log("Initial: start in progress complete");
			centerOnPinnedCards();
			setTimeout(() => {
				onReady();
			}, 3000); // 3000
		}
	}, [initialStartInProgress]);

	// this checks the initial loading process
	useEffect(() => {
		console.log("Initial ready cards count visible:", appState.cardsGridArray.length);
		if (appState.cardsGridArray.length > 10) {
			console.log("visible setting initial start in progress to false");
			setInitialStartInProgress(false);
		}
	}, [appState.cardsGridArray]);

	return (
		<div
			ref={cardsWrapperRef}
			className={`c-cardspace__wrapper ${extraClassName} ${uiState.isMoving ? "is-moving" : ""}`}
			style={{
				backgroundPosition: `${backgroundShiftMultiplier * spaceShiftX}px
					${backgroundShiftMultiplier * spaceShiftY}px`,
			}}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
			onTouchMove={handleTouchMove}
			onMouseEnter={() => {
				setIsMouseOver(true);
			}}
			onMouseLeave={() => {
				setIsMouseOver(false);
			}}
		>
			{uiState.activeCardIndex !== null &&
				appState.cardsData[appState.cardsGridArray[uiState.activeCardIndex].cardDataIdx].type !== "quote" && (
					<button className="c-card__close" onClick={handleCloseCard}>
						Close
					</button>
				)}
			{appState.cardsGridArray.map(({ gridX, gridY, zValue, shiftRatioX, shiftRatioY }, index) => {
				// const cardIsActive = index === activeCardIndex;
				// const cardIsHovered = index === hoveredCardIndex;

				const left = gridX * spaceGridWidth;
				const top = gridY * spaceGridHeight;
				// console.log("Grid pos:", gridX, gridY, left, top);

				const widthPadRatio = 2;

				var cardOnScreen;
				if (
					left < 0 - spaceShiftX - spaceGridWidth * widthPadRatio ||
					top < 0 - spaceShiftY - spaceGridHeight * widthPadRatio ||
					left > screenWidth - spaceShiftX + spaceGridWidth * widthPadRatio ||
					top > screenHeight - spaceShiftY + spaceGridHeight * widthPadRatio
				) {
					cardOnScreen = false;
				} else {
					cardOnScreen = true;
				}

				if (!cardOnScreen) return null;

				//distance between center point of the spacegrid that's displayed inside window
				// and the grid point for the card (which is left and top at this point)

				//FIXME: we should calculate left and top of the card based on shift ratio
				const deltaX = left + spaceShiftX - screenWidth / 2;
				const deltaY = top + spaceShiftY - screenHeight / 2;

				const shiftMultiplierX = (screenWidth / 2) * 0.4;
				const shiftMultiplierY = (screenHeight / 2) * 0.4;

				const planeShiftX = (deltaX / (screenWidth / 2)) * shiftMultiplierX * zValue;
				const planeShiftY = (deltaY / (screenHeight / 2)) * shiftMultiplierY * zValue;

				const targetLeft = left + spaceShiftX + uiState.defaultCardWidth * shiftRatioX + planeShiftX;
				const targetTop = top + spaceShiftY + uiState.defaultCardHeight * shiftRatioY + planeShiftY;

				// console.log("Zvalue:", zValue, left * zValueShiftX * zValue);

				return (
					<Card
						data-idx={index}
						key={index}
						cardGridArrayIndex={index}
						targetStyleLeft={targetLeft}
						targetStyleTop={targetTop}
						targetStyleTransitionDuration={isDragInProgress ? "0s" : "500ms"}
						isMoreMemoriesCard={false}
						screenWidth={screenWidth}
						screenHeight={screenHeight}
					/>
				);
			})}
		</div>
	);
}

export default CardsSpace;
