import { useState, useRef, useEffect } from "react";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import IconsVideo from "../../assets/icons/video.png";
// import { ReactComponent as IconVideo } from "../../assets/icons/video.svg";
import VideoPlayer from "../video-player/VideoPlayer";

export function CardVideo({ cardData, isHovered }) {
	const [isPlaying, setIsPlaying] = useState(isHovered);
	const videoRef = useRef(null);

	useEffect(() => {
		if (!videoRef.current) return;
		// console.log("CardVideo effect:", isHovered);
		if (isHovered === true) {
			videoRef.current.play();
			setIsPlaying(true);
		} else {
			videoRef.current.pause();
			setIsPlaying(false);
		}
	}, [isHovered]);

	return (
		<>
			<div className="c-card__photo-bg">
				<img alt="" src={cardData.thumb_url} style={{ opacity: isPlaying ? 0 : 1 }} />
				{cardData.video_preview_url && (
					<video ref={videoRef} loop={true} muted={true} playsInline={true} controls={false}>
						<source src={cardData.video_preview_url} type="video/mp4" />
					</video>
				)}
			</div>
			<div className="c-card__hover-title">
				<div>{cardData.title}</div>
				<ArrowRight />
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconsVideo} width={28} height={17} alt="" />
			</div>
		</>
	);
}

export function CardVideoActive({ cardData }) {
	const [isPlaying, setIsPlaying] = useState(false);
	const videoRef = useRef(null);

	const onHandlePlayClick = () => {
		setIsPlaying(true);
		videoRef.current.play();
	};

	return (
		<div className="content-holder">
			<div className="c-card__photo-year">{cardData.year}</div>
			<div className="c-card__header">{cardData.title}</div>
			<VideoPlayer posterUrl={cardData.thumb_url} mainVideoUrl={cardData.video_main_url} />
			<div className="c-card__photo-content">
				<div className="c-card__photo-content-desc">{cardData.desc}</div>
			</div>
		</div>
	);
}
