import React, { useEffect, useState, useContext } from "react";
import "./CardsRelated.scss";
import { AppStateContext } from "../../App";
import Card from "../Card/Card";

function CardsRelated({ currentCardGridArrayIndex, handleCardClick }) {
	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	const [cardGridIndices, setCardGridIndices] = useState([]);
	const { appState } = useContext(AppStateContext);

	useEffect(() => {
		var randomIndicies = [];
		var addedCardDataIndicies = [];
		const currentCardDataIdx = appState.cardsData[appState.cardsGridArray[currentCardGridArrayIndex].cardDataIdx];

		while (randomIndicies.length < 3) {
			const proposedIndex = getRandomInt(0, appState.cardsGridArray.length - 1);
			const proposedDataCardIndex = appState.cardsData[appState.cardsGridArray[proposedIndex].cardDataIdx];
			if (
				proposedDataCardIndex !== currentCardDataIdx &&
				addedCardDataIndicies.indexOf(proposedDataCardIndex) === -1
			) {
				randomIndicies.push(proposedIndex);
				addedCardDataIndicies.push(proposedDataCardIndex);
			}
		}

		setCardGridIndices(randomIndicies);
	}, [appState.cardsData, appState.cardsGridArray, currentCardGridArrayIndex]);

	return (
		<div className="c-related">
			<div className="content-holder">
				<div className="c-related__header">More moments</div>
				<div className="c-related__items">
					{cardGridIndices.map((cardGridIndex) => {
						return (
							<Card
								data-idx={cardGridIndex}
								key={cardGridIndex}
								cardGridArrayIndex={cardGridIndex}
								targetStyleLeft={0}
								targetStyleTop={0}
								targetStyleTransitionDuration={""}
								isActive={false}
								isMoreMemoriesCard={true}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default CardsRelated;
