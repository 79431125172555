import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";

if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
