import React from "react";

import styles from "./LoadingScreen.module.scss";
import { ReactComponent as LoadingText } from "../../assets/loading-text.svg";

function LoadingScreen(props) {
	return (
		<div className={styles.wrapper}>
			<div className={styles.badgeWrap}>
				<div className={styles.gradBlock}></div>
				<div className={styles.loadingWrap}>
					<LoadingText />
				</div>
			</div>
		</div>
	);
}

export default LoadingScreen;
