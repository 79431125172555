import React, { useState, useRef } from "react";
import { ReactComponent as VideoPlay } from "../../assets/icons/video-play.svg";

function VideoPlayer({ posterUrl, mainVideoUrl }) {
	const [isPlaying, setIsPlaying] = useState(false);
	const videoRef = useRef(null);

	const onHandlePlayClick = () => {
		setIsPlaying(true);
		videoRef.current.play();
	};

	return (
		<div className="c-card__video-wrap">
			<video
				ref={videoRef}
				poster={posterUrl}
				preload="auto"
				playsInline={true}
				controls={isPlaying ? true : false}
			>
				<source src={mainVideoUrl} type="video/mp4" />
			</video>
			{!isPlaying && (
				<button onClick={onHandlePlayClick} className="c-card__video-play">
					<VideoPlay />
				</button>
			)}
		</div>
	);
}

export default VideoPlayer;
